import { API } from 'aws-amplify';

export const handleResetPassword = (userData, successHandler, errorHandler) => {
    API.put('admin', '/users/reset-password', { body: { email: userData.email } })
    .then(() => successHandler())
    .catch(() => errorHandler());
};

export const CreateUserInApp = (userData, successHandler, errorHandler) => {
    API.post('admin', `/users/create-core/${userData.id}`, { body: {} })
    .then(() => successHandler())
    .catch(() => errorHandler());
};

export const SendDeepLink = (userData, successHandler, errorHandler) => {
    API.post('admin', `/users/${userData.id}/auth-deeplink`, { body: {} })
    .then(() => successHandler())
    .catch(() => errorHandler());
};

export const SendWebCabinetLogin = (userData, successHandler, errorHandler) => {
    API.post('admin', `/users/${userData.id}/web-cabinet-login`, { body: {} })
    .then(() => successHandler())
    .catch(() => errorHandler());
};
